import React, {useEffect, useState} from "react";
import AppNavbar from "../AppNavbar";
import moment from "moment";
import OrderList from "./OrderList";

import {useDispatch, useSelector} from "react-redux";
import {dateChanged, fetchOrders, truncateOrders} from "./OrderSlice";
import {useHistory} from "react-router-dom";

const Orders = (props) => {

    const [dateFilter, setDateFilter] = useState(moment().format('YYYY-MM-DD'));

    const orderStatus = useSelector(state => state.order.status);

    const dispatch = useDispatch();
    const history = useHistory();

    const handleErrors = (response) => {
        if (!response.ok && response.status === 401) {
            localStorage.removeItem("user");
            history.push("login");
        }
        return response;
    }

    useEffect(() => {
        if (orderStatus === 'idle') {
            dispatch(truncateOrders());
            dispatch(
                fetchOrders(dateFilter)
            ).unwrap().then(response => handleErrors(response));
        }
    }, [dispatch, orderStatus, dateFilter]);

    const changeDate = (event) => {
        event.preventDefault();
        let dateSelected = event.target.value;

        if ( !moment(dateSelected, "YYYY-MM-DD", true).isValid() ){
            dateSelected = moment().format('YYYY-MM-DD');
        }
        setDateFilter(dateSelected);
        dispatch(dateChanged());
    }

    return (
        <div>
            <AppNavbar/>
            <div className="container">
                <div className="col-auto">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                            <i className="bi bi-calendar-date"> </i>
                        </span>
                        <input type="date"
                               className="form-control"
                               name="deliveryDate"
                               id="deliverydate3"
                               value={dateFilter}
                               onChange={changeDate}
                               placeholder="Date du retrait"/>
                    </div>
                </div>
            </div>
            <OrderList test={"test"}/>
        </div>
    )
}

export default Orders;
