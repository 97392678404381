import {createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import authHeader from "../../services/auth-header";

const initialState = {
    driveOrders: [],
    deliveryOrders: [],
    status: 'idle',
    error: null,
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        dateChanged: (state) => {
            state.status = 'idle';
        },
        truncateOrders: state => {
            state.driveOrders = [];
            state.deliveryOrders = [];
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.status = "succeeded";
            action.payload.map && action.payload.map(( order ) => {
                if (order.creneau) {
                    state.driveOrders.push(order);
                } else if (order.creneauDelivery) {
                    state.deliveryOrders.push(order);
                }
                return order;
            });
        })
        .addCase(fetchOrders.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(fetchOrders.rejected, (state, action) => {
            state.status = "failed"
            state.error = "orders fetch failed"
        });

        builder.addCase(updateOrderStatus.fulfilled, (state, action) => {
            state.driveOrders = state.driveOrders.map( item => {
                if(item.creneau.idOrder === action.payload.id) {
                    return {
                        ...item,
                        opmOrder: action.payload
                    };
                } else {
                    return item;
                }
            });
            state.deliveryOrders = state.deliveryOrders.map( item => {
                if(item.creneauDelivery.idOrder === action.payload.id) {
                    return {
                        ...item,
                        opmOrder: action.payload
                    };
                } else {
                    return item;
                }
            });
        }).addCase(updateOrderStatus.rejected, (state, action) => {
            state.error = "status update failed"
        })
    }
})

export const { dateChanged, truncateOrders } = orderSlice.actions

export default orderSlice.reducer

// Selectors
export const tableActive = (state) => {
    return state.order.status === "succeeded";
};

// Thunks
export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async (date) => {
        const response = await fetch(`/api/drive/preparation-notes?deliveryDate=${date}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'Application/json',
                ...authHeader()
            }
        }).then( (response) => {
            return response.json();
        })
        return response;
    }
)

export const updateOrderStatus = createAsyncThunk(
    'orders/updateDriveOrderStatus',
    async (order) => {
        const responseDriveOrders = await fetch('/api/drive/preparation-notes/' + order.orderId, {
           method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'Application/json',
                ...authHeader()
            },
            body: JSON.stringify({
                id: order.orderId,
                prepared: order.preparationStatus
            })
        }).then(response => response.json());
        return responseDriveOrders;
    }
)
