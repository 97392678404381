import React from "react";
import authHeader from "../../services/auth-header";

function ExportListingButton(props) {

    function exportListing(event, category) {
        fetch("/api/stocks/listing?category=" + category, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                ...authHeader()
            }
        }).then( response => {
                return response.blob();
            }
        ).then( blob => {
            let url = window.URL.createObjectURL(new Blob([blob]));
            let a = document.createElement('a');
            a.href = url;
            a.setAttribute(
                'download',
                'listing-inventaire-' + category + '.pdf'
            );
            document.body.appendChild(a);
            a.click();
            a.parentNode.removeChild(a);
        });
    }

    return (
        <button type="button" className="btn btn-outline-primary" onClick={(e) => exportListing(e, props.category)}>
            <i className="bi bi-file-earmark"></i>
        </button>
    )
}

export default ExportListingButton;
