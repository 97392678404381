import React, {useState} from "react";
import AppNavbar from "../AppNavbar";
import moment from "moment";
import authHeader from "../../services/auth-header";
import Alert from "../tools/Alert";
import {useHistory} from "react-router-dom";

const Products = () => {

    const [isSpinnerHidden, hiddeSpinner] = useState(true);
    const [isButtonDisabled, disableButton] = useState(false);
    const [alertComponent, setAlertComponent] = useState();
    const history = useHistory();

    const handleErrors = (response) => {
        if (!response.ok && response.status === 401) {
            localStorage.removeItem("user");
            history.push("login");
        }
        return response;
    }

    const enableButton = (status) => {
        hiddeSpinner(status);
        disableButton(!status);
    }

    const showAlert = (status, alertType, alertMessage) => {
        if (status === false) {
            setAlertComponent(null);
        } else {
            setAlertComponent(<Alert alertType={alertType} message={alertMessage}/>)
            setTimeout(
                () => {showAlert(false, "", "")}
                , 5000
            )
        }
    }

    const majProduct = (event) => {
        event.preventDefault();

        enableButton(false);

        console.log(event.target[0].value);

        let productEan13 = event.target[0].value;

        fetch("/api/products/" + productEan13, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                ...authHeader()
            },
            body: JSON.stringify({"date_add": moment().format('YYYY-MM-DD hh:mm:ss')})
        }).then(
            handleErrors
        ).then( response => {
            if (response.status === 404){
                enableButton(true);
                showAlert(true, "warning", "Produit introuvable")
            } else if (response.status === 200) {
                enableButton(true);
                showAlert(true, "success", "Produit mis à jour");
            } else {
                showAlert(true, "danger", "Une erreur inconnue s'est produite")
                enableButton(true);
            }
        });
    }

    return (
        <div>
            <AppNavbar a/>
            <main>
                <div className="container">
                    {alertComponent}
                    <form onSubmit={majProduct}>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">EAN13 du produit</label>
                            <input type="text" className="form-control" id="productEan13Input" aria-describedby="productEan13"/>
                            <div id="productEan13" className="form-text">La date d'ajout du produit sera forcée à la date d'aujourd'hui pour le faire apparaitre en nouveauté</div>
                        </div>
                        <div>
                            <button disabled={isButtonDisabled} type="submit" className="btn btn-primary">Valider</button>
                            <div hidden={isSpinnerHidden} className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </form>
                </div>

            </main>
        </div>
    )
}

export default Products;
