import React, {useEffect} from "react";
import AppNavbar from "./AppNavbar";
import {Button, ListGroup, ListGroupItem} from "reactstrap";
import {Link, useHistory} from "react-router-dom";

import AuthService from "../services/auth";

function Home () {

    const history = useHistory();

    useEffect(() => {
        if(!AuthService.getCurrentUser()) {
            history.push("/login");
        }
    }, [history]);

    return (
        <div>
            <AppNavbar/>
            <main className="container">
                <div className="bg-light p-5 rounded">
                    <h1>Gestion Drive</h1>
                    <ListGroup>
                        <ListGroupItem className="bg-light">
                            <p className="lead">Préparation de commande</p>
                            <Button color="primary" tag={Link} to="/orders">Accéder aux commandes</Button>
                        </ListGroupItem>
                        <ListGroupItem className="bg-light">
                            <p className="lead">Gestion des stocks</p>
                            <Button color="primary" tag={Link} to ="/stocks">Inventaires</Button>
                        </ListGroupItem>
                        <ListGroupItem className="bg-light">
                            <p className="lead">Gestion des Produits</p>
                            <Button color="primary" tag={Link} to ="/products">Produits</Button>
                        </ListGroupItem>
                    </ListGroup>
                </div>
            </main>
        </div>
    )
}

export default Home;
