import React, {Component} from "react";
import authHeader from "../../services/auth-header";

class ExportBPButton extends Component {

    exportBP(event, reference) {
        fetch("/order/" + reference + "/export", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                ...authHeader()
            }
        }).then( response => {
            return response.blob();
        }
        ).then( blob => {
                let url = window.URL.createObjectURL(new Blob([blob]));
                let a = document.createElement('a');
                a.href = url;
                a.setAttribute(
                    'download',
                    reference + '.pdf'
                );
                document.body.appendChild(a);
                a.click();
                a.parentNode.removeChild(a);
        });
    }

    render() {
        return (
            <button type="button" className="btn btn-outline-primary" onClick={(e) => this.exportBP(e, this.props.reference)}>
                <i className="bi bi-file-earmark"></i>
            </button>
        )
    }
}

export default ExportBPButton;
