import React, {useEffect, useState} from "react";
import AppNavbar from "../AppNavbar";
import ExportListingButton from "./ExportListingButton";
import authHeader from "../../services/auth-header";

function Stocks () {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch("/api/categories", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'Application/json',
                ...authHeader()
            }
        }).then(function(response) {
            return response.json();
        }).then(function (data){
            setCategories(data);
        });
    }, [])

    function getLinesCategories() {
        return (
                categories.map(
                    function(categorie) {
                        return (
                            <tr>
                                <td>{categorie.categoryLang[0].name}</td>
                                <td>
                                    <ExportListingButton category={categorie.idCategory}/>
                                </td>
                            </tr>
                        )
                    }
                )
        )
    }

    return (
        <div>
            <AppNavbar/>
            <main>
                <div className="container">
                    <h1>Listing produits avec codes barres</h1>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead className="thead-inverse">
                                <tr>
                                    <th>Catégorie</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {getLinesCategories()}
                        </table>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Stocks;
