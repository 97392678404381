import React from "react";
import ExportBPButton from "./ExportBPButton";
import {useDispatch, useSelector} from "react-redux";
import { tableActive, updateOrderStatus} from "./OrderSlice";
import {useHistory} from "react-router-dom";

const OrderList = () => {

    const tableActivated = useSelector(tableActive);
    const driveOrders = useSelector((state) => state.order.driveOrders);
    const deliveryOrders = useSelector(state => state.order.deliveryOrders)
    const dispatch = useDispatch();
    const history = useHistory();

    const handleErrors = (response) => {
        if (!response.ok && response.status === 401) {
            localStorage.removeItem("user");
            history.push("login");
        }
        return response;
    }

    const updateStatus = (idOrder, status) => {
        dispatch(updateOrderStatus({
            orderId: idOrder,
            preparationStatus: status
        })).unwrap().then((response) => {
            handleErrors(response);
        });
    }

    const getLinesDrive = () => {
        if (Array.isArray(driveOrders) && driveOrders.length){
            return driveOrders.map(
                function(order, index) {
                    return <tr key={"drive" + index}>
                        <td>{order.creneau.order.reference}</td>
                        <td>{order.creneau.order.customer.email}</td>
                        <td>Drive</td>
                        <td>{order.creneau.day}</td>
                        <td>{order.creneau.hour}</td>
                        <td>{order.creneau.order.orderState.orderStateLangs[0].name}</td>
                        <td>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch"
                                       id="flexSwitchCheckDefault"
                                       checked={order.opmOrder ? order.opmOrder.prepared : false}
                                       onChange={() => {
                                           updateStatus(
                                               order.creneau.order.idOrder,
                                               !(order.opmOrder ? order.opmOrder.prepared : false)
                                           );
                                       }}
                                />
                            </div>
                        </td>
                        <td>
                            <ExportBPButton reference={order.creneau.order.reference}/>
                        </td>
                    </tr>
                }
            )
        } else return null;
    }

    const getLinesDelivery = () => {
        if (Array.isArray(deliveryOrders) && deliveryOrders.length){
            return deliveryOrders.map(
                function(order, index) {
                    return <tr key={"delivery" + index}>
                        <td>{order.creneauDelivery.order.reference}</td>
                        <td>{order.creneauDelivery.order.customer.email}</td>
                        <td>Livraison</td>
                        <td>{order.creneauDelivery.day}</td>
                        <td>{order.creneauDelivery.hour}</td>
                        <td>{order.creneauDelivery.order.orderState.orderStateLangs[0].name}</td>
                        <td>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch"
                                       id="flexSwitchCheckDefault"
                                       checked={order.opmOrder ? order.opmOrder.prepared : false}
                                       onChange={() => {
                                           updateStatus(
                                               order.creneauDelivery.order.idOrder,
                                               !(order.opmOrder ? order.opmOrder.prepared : false)
                                           );
                                       }}
                                />
                            </div>
                        </td>
                        <td>
                            <ExportBPButton reference={order.creneauDelivery.order.reference}/>
                        </td>
                    </tr>
                }
            )
        } else return null;
    }

    return (
        <main>
            <div className="container">
                <div className="table-responsive">
                    <table className="table table-hover" hidden={!tableActivated}>
                        <thead className="thead-inverse">
                            <tr>
                                <th>Reference</th>
                                <th>Client</th>
                                <th>Remise</th>
                                <th>Jour de retrait</th>
                                <th>Heure de retrait</th>
                                <th>Statut</th>
                                <th>Préparée</th>
                                <th> </th>
                            </tr>
                        </thead>
                        <tbody>
                            {getLinesDrive()}
                            {getLinesDelivery()}
                        </tbody>

                    </table>
                </div>
            </div>
        </main>
    )
}

export default OrderList;
