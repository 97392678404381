import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "/api/auth/"

function register (username, email, password) {
    return axios.post(API_URL + "signup", {
        username,
        email,
        password
    }, {
        headers: {
            ...authHeader()
        }
    });
};

function login (username, password) {
    return axios
        .post(API_URL + "signin", {
            username,
            password
        })
        .then((response) => {
            console.log(response);
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data
        })
}

function logout () {
    localStorage.removeItem("user");
}

function getCurrentUser () {
    return JSON.parse(localStorage.getItem("user"));
}

export default {
    register,
    logout,
    login,
    getCurrentUser
}
