import React, {useRef, useState} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import isEmail from "validator/es/lib/isEmail";

import AuthService from "../../services/auth";
import AppNavbar from "../AppNavbar";

function required(value) {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Champs requis!
            </div>
        );
    }
};

function validEmail(value) {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                Email non valide.
            </div>
        );
    }
};

function vusername(value) {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                Le nom d'utilisateur doit comprendre entre 3 et 20 caractères.
            </div>
        );
    }
};

function vpassword(value) {
    if (value.length < 4 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                Le mot de passe doit comprendre entre 4 et 40 caractères.
            </div>
        );
    }
};

function Register(props) {
    const form = useRef();
    const checkBtn = useRef();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [successful , setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    function onChangeUsername (e) {
        const username = e.target.value;
        setUsername(username);
    };

    function onChangeEmail (e) {
        const email = e.target.value;
        setEmail(email);
    };

    function onChangePassword (e) {
        const password = e.target.value;
        setPassword(password);
    };

    function handleRegister(e) {
        e.preventDefault();

        setMessage("");
        setSuccessful(false);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            AuthService.register(username, email, password).then(
                (response) => {
                    setMessage(response.data.message);
                    setSuccessful(true);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );
        }
    };

    return (
        <div>
            <AppNavbar/>
            <div className="col-md-12">
                <div className="card card-container">
                    <i className="bi bi-person-circle"></i>

                    <Form onSubmit={handleRegister} ref={form}>
                        {!successful && (
                            <div>
                                <div className="form-group">
                                    <label htmlFor="username">Username</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="username"
                                        value={username}
                                        onChange={onChangeUsername}
                                        validations={[required, vusername]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        value={email}
                                        onChange={onChangeEmail}
                                        validations={[required, validEmail]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        value={password}
                                        onChange={onChangePassword}
                                        validations={[required, vpassword]}
                                    />
                                </div>

                                <div className="form-group">
                                    <button className="btn btn-primary btn-block">Sign Up</button>
                                </div>
                            </div>
                        )}

                        {message && (
                            <div className="form-group">
                                <div
                                    className={ successful ? "alert alert-success" : "alert alert-danger" }
                                    role="alert"
                                >
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Register;
