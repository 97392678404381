import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import AuthService from "../services/auth";

export default function AppNavbar(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [showOrders, setShowOrders] = useState(false);
    const [showStocks, setShowStocks] = useState(false);
    const [showProducts, setShowProducts] = useState(false);
    const [showSignup, setShowSSignup] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);

    useEffect(() => {
        const user = AuthService.getCurrentUser();

        if (user) {
            setCurrentUser(user);
            setShowOrders(user.roles.includes("ROLE_USER"));
            setShowStocks(user.roles.includes("ROLE_USER"));
            setShowProducts(user.roles.includes("ROLE_USER"));
            setShowSSignup(user.roles.includes("ROLE_ADMIN"));
        }
    }, []);

    function logOut() {
        AuthService.logout();
    }

    function toggle() {
        setIsOpen(!isOpen);
    }

    return (
        <div>
            <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dark mb-4">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">Gestion Drive</a>
                    <button className="navbar-toggler" type="button" data-bs-target="#navbarCollapse"
                            data-bs-toggle="collapse" aria-controls="navbarCollapse"
                            aria-expanded="false" aria-label="Toggle navigation"
                            onClick={toggle}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav me-auto mb-2 mb-md-0">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/">Accueil</Link>
                            </li>
                            { showOrders && (
                                <li className="nav-item">
                                    <Link className="nav-link" to="/orders">Commandes</Link>
                                </li>
                            )}
                            { showStocks && (
                                <li className="nav-item">
                                    <Link className="nav-link" to="/stocks">Stocks</Link>
                                </li>
                            )}
                            { showProducts && (
                                <li className="nav-item">
                                    <Link className="nav-link" to="/products">Produits</Link>
                                </li>
                            )}
                            { showSignup && (
                                <li className="nav-item">
                                    <Link className="nav-link" to="/register">Nouvel utilisateur</Link>
                                </li>
                            )}
                            { currentUser ? (
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login" onClick={logOut}>Déconnexion</Link>
                                </li>
                            ) : (
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login">Connexion</Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}
