import './App.css';
import {Component} from "react";
import {Route, Switch, BrowserRouter} from "react-router-dom";
import Home from "./components/Home";
import Orders from "./components/order/Orders";
import Stocks from "./components/stock/Stocks";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Products from "./components/product/Products";

import {Provider} from "react-redux";
import store from './store';

class App extends Component {
  render() {
    return (
        <Provider store={store}>
            <BrowserRouter>
              <Switch>
                <Route path='/login' component={Login}/>
                <Route path='/register' component={Register}/>
                <Route path='/' exact={true} component={Home}/>
                <Route path='/orders' component={Orders}/>
                <Route path='/stocks' component={Stocks}/>
                <Route path='/products' component={Products}/>
              </Switch>
            </BrowserRouter>
        </Provider>
    )
  }
}

export default App;
